import { createContext, PropsWithChildren, useEffect, useMemo, useState, useCallback, useRef } from 'react';
import React from 'react';
import { AddressData, LocationData, LocationUserData } from "../models/location";
import { getAddressFromCoords } from "../services/locationService";
import { useToast } from '../hooks/useToast';
import { ToastTypesList } from '../utils/constants';

export type StoresFiltersTypes = 'TRADE' | 'ONPREMISE' | 'FAVORITE';
export type StoreView = "map" | "list";
export interface HomePagePreviousState {
    selectedStoreFilters: StoresFiltersTypes[];
    storeView: StoreView;
};

export interface LocationContextProps {
    locationUserData: LocationUserData,
    setLocationUserData: React.Dispatch<React.SetStateAction<LocationUserData>>,
    getLocation: (getOnlyCoords?: boolean) => Promise<LocationData | null>,
    isLocationInputFocused: boolean,
    setIsLocationInputFocused: React.Dispatch<React.SetStateAction<boolean>>,
    selectedStoreFilters: StoresFiltersTypes[];
    setSelectedStoreFilters: React.Dispatch<React.SetStateAction<StoresFiltersTypes[]>>;
    homePagePreviousState: HomePagePreviousState | null;
    setHomePagePreviousState: React.Dispatch<React.SetStateAction<HomePagePreviousState | null>>
    hasStartedTour: boolean,
    setHasStartedTour: React.Dispatch<React.SetStateAction<boolean>>,
    showFakeDataTour:boolean,
    setShowFakeDataTour:React.Dispatch<React.SetStateAction<boolean>>,
    neverShowTourAgain:boolean,
    setNeverShowTourAgain:React.Dispatch<React.SetStateAction<boolean>>,  
    favoritesCount:number,
    setFavoritesCount:React.Dispatch<React.SetStateAction<number>>,  
}


export const locationContextDefaultValues: LocationContextProps = {
    locationUserData: {
        locationType: 'none',
        location: null,

    },
    setLocationUserData: () => { },
    getLocation: async (getOnlyCords?: boolean) => null,
    isLocationInputFocused: false,
    setIsLocationInputFocused: () => { },
    selectedStoreFilters: ['TRADE'],
    setSelectedStoreFilters: () => { },
    homePagePreviousState: null,
    setHomePagePreviousState: () => { },
    hasStartedTour:false,
    setHasStartedTour:()=>{},
    showFakeDataTour:false,
    setShowFakeDataTour:()=>{},
    neverShowTourAgain:false,
    setNeverShowTourAgain:()=>{},
    favoritesCount:0,
    setFavoritesCount:()=>{},
}

export const LocationContext = createContext(locationContextDefaultValues);

export const LocationProvider = ({ children }: PropsWithChildren) => {

    // Initialize state with default values
    const [locationUserData, setLocationUserData] = useState<LocationUserData>(() => {
        // Retrieve persisted data from storage when component mounts
        const storedData = localStorage.getItem('location');
        return storedData ? JSON.parse(storedData) : locationContextDefaultValues.locationUserData;
    });

    const [isLocationInputFocused, setIsLocationInputFocused] = useState(locationContextDefaultValues.isLocationInputFocused)

    const [selectedStoreFilters, setSelectedStoreFilters] = useState<StoresFiltersTypes[]>(locationContextDefaultValues.selectedStoreFilters)

    const [homePagePreviousState, setHomePagePreviousState] = useState<HomePagePreviousState | null>(locationContextDefaultValues.homePagePreviousState);

    const [hasStartedTour, setHasStartedTour] = useState(locationContextDefaultValues.hasStartedTour);

    const [showFakeDataTour,setShowFakeDataTour] = useState(locationContextDefaultValues.showFakeDataTour);

    const [neverShowTourAgain, setNeverShowTourAgain] = useState(locationContextDefaultValues.neverShowTourAgain);

    const [favoritesCount, setFavoritesCount] = useState(locationContextDefaultValues.favoritesCount);

    const { showToast } = useToast();

    let timeoutId = useRef<NodeJS.Timeout>();
    // Update state and persist data whenever it changes
    useEffect(() => {
        localStorage.setItem('location', JSON.stringify(locationUserData));
    }, [locationUserData]);

    useEffect(() => {

        return () => clearTimeout(timeoutId.current);
    }, [])

    const getLocation = useCallback(async () => {
        if (navigator.geolocation) {
            try {
                const position = await new Promise((resolve: PositionCallback, reject: PositionErrorCallback) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                });

                let addressSelected: AddressData;

                if (position.coords.latitude !== locationUserData.location?.latitude || position.coords.longitude !== locationUserData.location.longitude) {
                    const address = await getAddressFromCoords(position.coords.latitude, position.coords.longitude);

                    const addressSplit = address?.split(',');

                    addressSelected = {
                        mainText: addressSplit![0],
                        secondaryText: address?.slice(address.indexOf(','))!,
                        fullAddress: address!,
                    }


                    const newLocationData: LocationUserData = {
                        locationType: 'gps',
                        location: {
                            longitude: position.coords.longitude,
                            latitude: position.coords.latitude,
                            addressPrompts: [addressSelected],
                            addressSelected,
                        }
                    }
                    if (newLocationData.location?.latitude !== locationUserData.location?.latitude
                        || newLocationData.locationType !== locationUserData.locationType
                        || newLocationData.location?.longitude !== locationUserData.location?.longitude
                        || newLocationData.location?.addressSelected.fullAddress !== locationUserData.location?.addressSelected.fullAddress
                    ) {
                        setLocationUserData(newLocationData);
                        console.log('geolocation update', newLocationData)
                    }
                    showToast(ToastTypesList.SUCCESS, 'Hemos actualizado tu ubicación')
                } else {
                    await new Promise((resolve) => {
                        timeoutId.current = setTimeout(() => resolve(true), 700);
                    })
                }


            } catch (err: any) {
                switch (err.code) {
                    case err.PERMISSION_DENIED:
                        console.log('User denied the request for Geolocation.');
                        showToast(ToastTypesList.INFO,'No has permitido al navegador obtener tu ubicación, revisa tu configuración')
                        if(!neverShowTourAgain){
                            setLocationUserData({locationType:'gps',location:null})
                        }
                        break;
                    case err.POSITION_UNAVAILABLE:
                        console.log('Location information is unavailable.');
                        break;
                    case err.TIMEOUT:
                        console.log('The request to get user location timed out.');
                        break;
                    default:
                        console.log('An unknown error occurred.');
                        break;
                }
            } finally {
                setIsLocationInputFocused(false);
            }
        } else {
            console.log('Geolocation is not supported by this browser.');
        }
        return locationUserData.location;
    }, [locationUserData.location, neverShowTourAgain]);

    const contextValue = useMemo(() => ({
        locationUserData,
        setLocationUserData,
        getLocation,
        isLocationInputFocused,
        setIsLocationInputFocused,
        selectedStoreFilters,
        setSelectedStoreFilters,
        homePagePreviousState,
        setHomePagePreviousState,
        hasStartedTour,
        setHasStartedTour,
        showFakeDataTour,
        setShowFakeDataTour,
        neverShowTourAgain,
        setNeverShowTourAgain,
        favoritesCount,
        setFavoritesCount,
    }), [locationUserData,
        setLocationUserData,
        getLocation,
        isLocationInputFocused,
        setIsLocationInputFocused,
        selectedStoreFilters,
        setSelectedStoreFilters,
        homePagePreviousState,
        setHomePagePreviousState,
        hasStartedTour,
        setHasStartedTour,
        showFakeDataTour,
        setShowFakeDataTour,
        neverShowTourAgain,
        setNeverShowTourAgain,
        favoritesCount,
        setFavoritesCount,
    ])

    return (
        <LocationContext.Provider
            value={contextValue}>
            {children}
        </LocationContext.Provider>
    )
}
