import {useEffect} from 'react';
import { ReactComponent as StoresIcon } from "../../assets/ilustraciones/stores.svg";
import { ReactComponent as FoodStoresIcon } from "../../assets/ilustraciones/food_stores.svg";
import { ReactComponent as FavoritesIcon } from "../../assets/ilustraciones/favorites.svg";
import { cn } from "../../utils/cn";
import { useLocationContext } from "../../hooks/useLocationContext";
import { StoresFiltersTypes } from "../../context/LocationContext";

const StoresFilter = () => {
  const {
    selectedStoreFilters,
    setSelectedStoreFilters,
    favoritesCount,
  } = useLocationContext();
  
  useEffect(()=>{
    if(favoritesCount===0 && selectedStoreFilters.includes('FAVORITE')){
      setSelectedStoreFilters(['TRADE']);
    }
  },[favoritesCount])

  const handleFilterClick = (type: StoresFiltersTypes) => {
    if (type === "FAVORITE") {
      if (!selectedStoreFilters.includes("FAVORITE")) {
        setSelectedStoreFilters(["FAVORITE"]);
      }
    }
    if (type === "TRADE" || type === "ONPREMISE") {
      const newArray = [...selectedStoreFilters];
      if (!selectedStoreFilters.includes(type)) {
        newArray.push(type);
      } else {
        if (selectedStoreFilters.length > 1) {
          const index = newArray.indexOf(type);
          newArray.splice(index, 1);
        }
      }
      if (selectedStoreFilters.includes("FAVORITE")) {
        const index = newArray.indexOf("FAVORITE");
        newArray.splice(index, 1);
      }
      setSelectedStoreFilters(newArray);
    }
  };

  return (
    <div className="step5 py-4 flex gap-4 justify-evenly w-full">
      <div className="flex flex-col gap-2 items-center">
        <div
          id="buttonStores"
          className={cn(
            "rounded-full w-10 p-2 cursor-pointer drop-shadow-[0_4px_4px_#00000075]",
            selectedStoreFilters.includes("TRADE") ? "bg-[#76A8FF]" : "bg-white"
          )}
          onClick={() => handleFilterClick("TRADE")}
        >
          <StoresIcon />
        </div>
        <div className="font-roboto text-xs font-semibold text-[#070561]">
          Tiendas
        </div>
      </div>
      {/* <div className="flex flex-col gap-2 items-center">
        <div
          id="buttonFoodStores"
          className={cn(
            "rounded-full w-10 p-2 cursor-pointer drop-shadow-[0_4px_4px_#00000075]",
            selectedStoreFilters.includes("ONPREMISE")
              ? "bg-[#76A8FF]"
              : "bg-white"
          )}
          onClick={() => handleFilterClick("ONPREMISE")}
        >
          <FoodStoresIcon />
        </div>
        <div className="font-roboto text-xs font-semibold text-[#070561]">
          Alimentos
        </div>
      </div> */}
      {favoritesCount > 0 && (
        <div className="flex flex-col gap-2 items-center">
          <div
            id="buttonFavorites"
            className={cn(
              "rounded-full w-10 h-10 p-2 flex justify-center items-center cursor-pointer drop-shadow-[0_4px_4px_#00000075]",
              selectedStoreFilters.includes("FAVORITE")
                ? "bg-[#76A8FF]"
                : "bg-white"
            )}
            onClick={() => handleFilterClick("FAVORITE")}
          >
            <FavoritesIcon />
          </div>
          <div className="font-roboto text-xs font-semibold text-[#070561]">
            Favoritas
          </div>
        </div>
      )}
    </div>
  );
};

export default StoresFilter;
