import React from 'react'
import ArrowTooltip from './ArrowTooltip'
import FooterStep from './Footer'
import { StepProps } from './setup'

const Step4 = ({ onSkip, onContinue }: StepProps) => {
    return (
        <div className='bg-[#F0F3F4] rounded-[14px] px-4 py-8 relative flex flex-col gap-4 text-[#070561] font-roboto'>
            <ArrowTooltip horizontalPositionClass='right-[45%]' />
            <div className="flex flex-col gap-1 items-center">
                <div className='font-extrabold text-sm'>Accede al código de la promoción</div>
                <div className="font-libre-franklin text-xs w-72">
                    Accede a la promoción de tu interés y encuentra el código para canjear en la tienda
                </div>
            </div>
            <FooterStep stepNumber={4} onSkip={onSkip} onContinue={onContinue} />
        </div>
    )
}

export default Step4
