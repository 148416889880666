import React from 'react'
import { Movement } from '../../models/history'
import { format } from 'date-fns';
import { es } from 'date-fns/locale'

type MovementsCardProps = {
   movement:Movement;
}

const MovementsCard = ({ movement }: MovementsCardProps) => {
    return (
        <div className='bg-white rounded-3xl p-4 flex items-center font-roboto text-[#384753]'>
            <div className="w-48">
                <div className="text-sm font-semibold">{movement.promotion.name}</div>
                <div className="text-[10px]">{ format(movement.exchange_date,'dd MMM yyyy, HH:mm', {locale: es}) }</div>
            </div>
            <div className="text-sm font-semibold ml-1">
                $ {movement.revenue.toLocaleString()}
            </div>
        </div>
    )
}

export default MovementsCard
