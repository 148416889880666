import React, { useEffect, useState } from "react";
import copys from "../utils/copys";
import ProfileDataItem from "../components/ProfileDataItem";
import { useAuthContext } from "../hooks/useAuthContext";
import { useNavigate, Link } from 'react-router-dom';
import Modal from "../components/modals/Modal";
import ModalLogout from "../components/modals/ModalLogout";
import { genders } from "../utils/constants";
import * as gtmService from "../services/gtmService";
import Header from "./layout/Header";
import { TermsOrPrivacy } from '../utils/constants';
import { termsAndPrivacy } from '../utils/copys';

const ScreenProfile = () => {
  const { userData, setUserData } = useAuthContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonBackLabel, setButtonBackLabel] = useState(copys.buttonBack);
  const navigate = useNavigate();

  const logout = () => {
    gtmService.pushClickButton(copys.modalLogoutButtonOk);
    setUserData({ isLogged: false, otpRetries: [] });
    localStorage.setItem('auth',JSON.stringify({ isLogged: false, otpRetries: [] }));
    navigate("/");
  };

  useEffect(() => {
    if (userData.isLogged === false) {
      navigate("/");
    }
    gtmService.pushPageView("/cliente-perfil", "Cliente: Perfil");
    if (userData.navigatedFromHome) {
      setButtonBackLabel(copys.backButtonGoHome);
    }
    const userDataTemp = { ...userData };
    delete userDataTemp.navigatedFromHome;
    setUserData(userDataTemp);
  }, []);

  return (
    <div className="flex flex-col justify-start h-[80vh]">
      <Header
        customHeader={buttonBackLabel}
        backButtonCallback={() => navigate(-1)}
      />
      <Modal isOpen={isModalOpen} isCentered>
        <ModalLogout
          onClickOk={() => logout()}
          onClickCancel={() => setIsModalOpen(false)}
        />
      </Modal>
      <div className="flex flex-col justify-start gap-6 pb-10">
        <div
          className="flex pt-5 pb-2 px-4 items-start text-text_strong font-libre-franklin text-3xl font-bold"
          id="profileTitle"
        >
          {copys.titleProfileScreen}
        </div>
        <div className="flex flex-col items-start">
          <ProfileDataItem
            header={copys.profileHeaderName}
            value={userData.user?.name!}
            id="name"
          />
          {userData.user?.birth_year ? (
            <ProfileDataItem
              header={copys.profileHeaderYear}
              value={userData.user?.birth_year}
              id={"birthYear"}
            />
          ) : (
            ""
          )}
          {userData.user?.gender ? (
            <ProfileDataItem
              header={copys.profileHeaderGender}
              value={genders[userData.user?.gender]}
              id={"gender"}
            />
          ) : (
            ""
          )}
        </div>
        <div className="flex justify-center items-center pt-3">
          <button
            id="logoutButton"
            onClick={() => setIsModalOpen(true)}
            className="flex justify-center items-center py-2 px-4 text-text_strong text-center font-roboto text-base font-semibold"
          >
            {copys.buttonLogout}
          </button>
        </div>
      </div>
      <footer className="flex flex-col items-center gap-2 bg-blue_strong py-6 shrink-0 fixed bottom-0 w-full">
        <div className="font-roboto text-sm font-normal text-white underline">
          <Link id="termsLink" to={"/terms/" + TermsOrPrivacy.TERMS}>
            {termsAndPrivacy.termsFooterLabel}
          </Link>
        </div>
        <div className="font-roboto text-sm font-normal text-white underline">
          <Link id="privacyLink" to={"/terms/" + TermsOrPrivacy.PRIVACY}>
            {termsAndPrivacy.privacyFooterLabel}
          </Link>
        </div>
        <div className="font-roboto text-sm font-normal text-white">
          {copys.footerCopyright}
        </div>
      </footer>
    </div>
  );
};

export default ScreenProfile;
