import React, { useEffect, useState } from "react";
import IconRoundedCheckboxChecked from "./icons/IconRoundedCheckbox-checked";
import IconRoundedCheckbox from "./icons/IconRoundedCheckbox";
import { Link } from "react-router-dom";
import { TermsOrPrivacy } from "../utils/constants";

type AcceptTermRegisterProps = {
    onChange: (value: boolean) => void;
    defaultValue: boolean;
}

const AcceptTermsRegister = ({ onChange, defaultValue }: AcceptTermRegisterProps) => {

    const [isChecked, setIsChecked] = useState(defaultValue);

    useEffect(() => {
        onChange(isChecked);
    }, [isChecked])

    return (
        <div className="flex gap-2 container px-3">
            <div id="checkboxAcceptTerms" onClick={() => setIsChecked(prev => !prev)}>
                {
                    isChecked ?
                        <IconRoundedCheckboxChecked />
                        :
                        <IconRoundedCheckbox />
                }
            </div>
            <div className="font-roboto text-xs text-white text-start">
                He leído y acepto los{" "}
                <Link id='termsLink' to={'/terms/' + TermsOrPrivacy.TERMS} className="underline">Términos y Condiciones</Link>.
            </div>
        </div>
    );
};

export default AcceptTermsRegister;
