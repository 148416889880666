import React from 'react'
import { ReactComponent as MapIcon } from "../assets/icons/map-lg.svg";
import { useLocationContext } from '../hooks/useLocationContext';
import copys from '../utils/copys';


const ModalNoStores = () => {

    const { selectedStoreFilters, locationUserData } = useLocationContext();

    return (
        <div className="absolute w-full h-full flex justify-center items-center">
            <div className="flex justify-center items-center">
                <div className="w-[80%] bg-white rounded-3xl p-10 flex flex-col gap-6 items-center text-[#070561]">
                    <div className="font-roboto font-semibold">
                        Por favor, ingresa otra dirección
                    </div>
                    <div className="flex gap-2">
                        <div>
                            <MapIcon />
                        </div>
                        <div className="font-roboto text-sm">
                            {selectedStoreFilters.includes('FAVORITE') && `${copys.labelFavoriteNotFound}`}
                            {selectedStoreFilters.includes('TRADE') && selectedStoreFilters.includes('ONPREMISE') && `${copys.labelFoodStoresAndFoodNotFound}`}
                            {selectedStoreFilters.includes('TRADE') && !selectedStoreFilters.includes('ONPREMISE') && `${copys.labelStoresNotFound}`}
                            {!selectedStoreFilters.includes('TRADE') && selectedStoreFilters.includes('ONPREMISE') && `${copys.labelFoodStoresNotFound}`}
                            {' '}
                            {
                                locationUserData.location?.addressSelected
                                    .fullAddress
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalNoStores
