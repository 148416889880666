import React from 'react'
import { cn } from '../../utils/cn';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useLocationContext } from '../../hooks/useLocationContext';

export type TabsOptions = 'promotions' | 'stores' ;

type TabsProps = {
    selectedTab: TabsOptions,
    setSelectedTab: React.Dispatch<React.SetStateAction<TabsOptions>>,
}
const Tabs = ({ selectedTab, setSelectedTab }: TabsProps) => {

    const { userData } = useAuthContext();
    const { locationUserData } = useLocationContext()

    const handleClickStores = () => {
        if (userData.isLogged && locationUserData.locationType !== 'none') {
            setSelectedTab('stores')
        }
    }

    return (
        <div className='flex justify-between gap-8'>
            <div id='tabPromotions' className={cn('font-roboto font-semibold text-sm text-[#070561] px-3 py-1 cursor-pointer', selectedTab === 'promotions' && 'text-white bg-[#5553BE] rounded-t-[4px] border-b border-[#100E9D]')} onClick={() => setSelectedTab('promotions')}>
                Promociones
            </div>
            <div id='tabStores' className={cn('step5 font-roboto font-semibold text-sm text-[#070561] px-3 py-1 cursor-pointer', selectedTab === 'stores' && 'text-white bg-[#5553BE] rounded-t-[4px]', (!userData.isLogged || locationUserData.locationType === 'none') && 'text-[#95A2AD]')} onClick={handleClickStores}>
                Centros de canje
            </div>
        </div>
    )
}

export default Tabs
