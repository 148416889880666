import { useCallback, useEffect, useRef, useState } from "react";
import { getPromos } from "../services/promosService";
import Card from "../components/carousel/Card";
import copys from "../utils/copys";
import ScreenLoading from "./ScreenLoading";
import { useAuthContext } from "../hooks/useAuthContext";
import * as gtmService from "../services/gtmService";
import { usePromoContext } from "../hooks/usePromoContext";
import { useToast } from "../hooks/useToast";
import { localStorageTourName, ToastTypesList } from "../utils/constants";
import * as Sentry from "@sentry/react";
import { promoContextDefaultValues } from "../context/PromoContext";
import { PromotionItem } from "../models/promotions";
import Footer from "./layout/Footer";
import Header from "./layout/Header";
import LocationSection from "../components/screenHome/LocationSection";
import Tabs, { TabsOptions } from "../components/screenHome/Tabs";
import { useLocationContext } from "../hooks/useLocationContext";
import DisclaimerLogin from "../components/screenHome/DisclaimerLogin";
import DisclaimerLocation from "../components/screenHome/DisclaimerLocation";
import {
  GoogleMap,
  useLoadScript,
  Libraries,
  Marker,
  Circle,
} from "@react-google-maps/api";
import { getStoresNearBy } from "../services/authService/authService";
import { List, Map } from "lucide-react";
import { LocationUserData, Store } from "../models/location";
import { ScreenStoreAvailable } from "./ScreenStoreAvailable";
import StoresFilter from "../components/screenHome/StoresFilter";
import { useNavigate } from "react-router-dom";
import NoteAboutNoStores from "../components/screenHome/NoteAboutNoStores";
import { locationContextDefaultValues, StoreView } from "../context/LocationContext";
import { ReactComponent as FilterIcon } from '../assets/icons/filter.svg'
import ModalNoStores from "../components/ModalNoStores";
import { Walktour } from "walktour";
import Modal from "../components/modals/Modal";
import Step1 from "../components/walkthrough/Step1";
import { useWalktour } from "../hooks/useWalktour";
import demoCouponImg from '../assets/img/demo_coupon.png'
import demoMapImg from '../assets/img/demo_map.png'
import { cn } from "../utils/cn";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function ScreenHome() {
  const [promos, setPromos] = useState<PromotionItem[]>([]);
  const [showLoading, setShowLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState<TabsOptions>("promotions");
  const [storeView, setStoreView] = useState<StoreView>("map");
  const [stores, setStores] = useState<Store[]>([]);
  const [showDisclaimerLocation, setShowDisclaimerLocation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isStep1TourOpen, setIsStep1TourOpen] = useState(false);
  const { userData } = useAuthContext();
  const { setPromoData } = usePromoContext();
  const {
    locationUserData,
    isLocationInputFocused,
    setIsLocationInputFocused,
    selectedStoreFilters,
    setSelectedStoreFilters,
    setHomePagePreviousState,
    homePagePreviousState,
    hasStartedTour,
    setHasStartedTour,
    getLocation,
    showFakeDataTour,
    setShowFakeDataTour,
    neverShowTourAgain,
    setNeverShowTourAgain,
    setFavoritesCount,
    setLocationUserData,
  } = useLocationContext();
  const { showToast } = useToast();
  const navigate = useNavigate();

  const hasPollingStoppedRef = useRef(false);
  const selectedTabRef = useRef<TabsOptions>("promotions");
  const isMounted = useRef(false); // Flag to track component mount

  const [libraries] = useState<Libraries>(["places"]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY!,
    libraries,
  });

  const locationUserDataRef = useRef<LocationUserData>(locationUserData);
  const isLocationInputFocusedRef = useRef<boolean>(false);

  const { steps } = useWalktour(setSelectedTab)

  const navigateToStoreDetail = (store: Store) => {
    setHomePagePreviousState({
      storeView,
      selectedStoreFilters,
    })
    navigate(
      `/store/details/${store.shopkeeper_id}?lat=${locationUserData.location?.latitude}&lng=${locationUserData.location?.longitude}`
    )
  }

  const skipTour = () => {
    localStorage.setItem(localStorageTourName, 'yes');
    if (locationUserData.location === null) {
      setLocationUserData(locationContextDefaultValues.locationUserData);
    }
    setIsStep1TourOpen(false);
    setHasStartedTour(false);
    setShowFakeDataTour(false);
    setSelectedTab('promotions');
    setNeverShowTourAgain(true);
  }

  const startTour = () => {
    setIsStep1TourOpen(false);
    setHasStartedTour(true)
  }

  useEffect(() => {
    locationUserDataRef.current = locationUserData;
  }, [locationUserData]);

  useEffect(() => {
    isLocationInputFocusedRef.current = isLocationInputFocused;
  }, [isLocationInputFocused])

  useEffect(() => {
    if (
      selectedTab === "stores" &&
      locationUserData.locationType !== "none"
    ) {
      setIsLoading(true)
      getStoresNearBy(
        locationUserData.location?.latitude.toString()!,
        locationUserData.location?.longitude.toString()!,
        selectedStoreFilters
      ).then((res) => {
        if (res.successful) {
          setStores(res.data);
        } else {
          setStores([]);
        }
      }).finally(() => setIsLoading(false));
    }

    if (selectedTab === "promotions" && isMounted.current) {
      loadPromos();
    }
  }, [selectedTab, locationUserData, selectedStoreFilters, storeView]);

  useEffect(() => {
    selectedTabRef.current = selectedTab;
  }, [selectedTab]);

  const center = {
    lat: locationUserData.location?.latitude!,
    lng: locationUserData.location?.longitude!,
  };

  const loadPromos = useCallback(async () => {
    if (!userData.isLogged && isMounted.current) {
      return;
    }
    setIsLoading(true);
    const { successful, data, errors, message } = await getPromos(
      userData.isLogged ? locationUserDataRef.current.location : null
    );
    if (successful) {
      setPromos(data);
    } else {
      console.log(errors);
      showToast(ToastTypesList.ERROR, copys.toastErrorMessageDefault);
      gtmService.pushNotification("error", message);
      Sentry.captureException(errors, {
        tags: {
          flow: "load_promotions",
          user_id: userData?.user?.id,
        },
      });
    }
    setIsLoading(false);
    setTimeout(() => setShowLoading(false), 500);
  }, [showToast, userData, isMounted]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    setPromoData(promoContextDefaultValues.promoData);
    if (homePagePreviousState !== null) {
      setSelectedTab('stores');
      setSelectedStoreFilters(homePagePreviousState.selectedStoreFilters);
      setStoreView(homePagePreviousState.storeView);
    }
    setHomePagePreviousState(null);

    if (userData.isLogged) {
      gtmService.pushPageView("/cliente-home", "Cliente: Home");
    } else {
      gtmService.pushPageView("/cliente-inicio", "Cliente: Inicio");
    }

    const startPolling = async () => {
      if (!hasPollingStoppedRef.current) {
        if (
          document.visibilityState === "visible" &&
          selectedTabRef.current === "promotions" &&
          !isLocationInputFocusedRef.current
        ) {
          await loadPromos();
        }
        timeoutId = setTimeout(startPolling, 10000); // Start next polling after 10 seconds
      }
    };
    
    if (locationUserData.locationType === 'gps') {
      getLocation().then();
    }
    
    startPolling(); // Start the initial fetch
    isMounted.current = true; // Set the flag to true after mount

    if (hasStartedTour) { //this in case the user was in middle of tour and pressed the back button
      setHasStartedTour(false);
      setShowFakeDataTour(false);
    }

    if (!localStorage.getItem(localStorageTourName)) {
      if (userData.isLogged) {
        setIsStep1TourOpen(true);
      }
    } else {
      setNeverShowTourAgain(true);
    }

    return () => {
      hasPollingStoppedRef.current = true;
      clearTimeout(timeoutId); // Cleanup on unmount
    };
  }, []);

  useEffect(() => {
    if (locationUserData.locationType === "none") {
      setShowDisclaimerLocation(true);
    } else {
      setShowDisclaimerLocation(false);
    }
  }, [locationUserData]);

  useEffect(() => {
    setFavoritesCount(stores.filter(store => store.isFavorite).length);
  }, [stores])

  return (
    <>
      <Header
        customHeader={
          isLocationInputFocused ? copys.headerWhenTypingLocation : undefined
        }
        backButtonCallback={() => setIsLocationInputFocused(false)}
      />
      <Modal isCentered isOpen={isStep1TourOpen} >
        <Step1 onSkip={skipTour} onContinue={() => startTour()} />
      </Modal>
      <Walktour
        steps={steps}
        isOpen={hasStartedTour && !neverShowTourAgain}
        maskPadding={0}
        customCloseFunc={(_tourLogic) => { skipTour(); }}
      />
      <div className={cn("flex flex-col items-center gap-4", !showFakeDataTour && "pb-5")}>
        {showLoading && <ScreenLoading isHome />}
        {userData.isLogged ? (
          <>
            {!isLocationInputFocused && (
              <div className="font-libre-franklin font-semibold text-xs text-[#070561] pt-5 pb-1">
                {copys.homeSlogan}
              </div>
            )}
            {isLoaded && <LocationSection />}
            {!isLocationInputFocused && showDisclaimerLocation && !showFakeDataTour && (
              <DisclaimerLocation />
            )}
          </>
        ) : (
          <DisclaimerLogin />
        )}

        {!isLocationInputFocused && (
          <>
            <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            {selectedTab === "promotions" ? (
              <div className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-center items-center px-4 pb-28 gap-3">
                {
                  showFakeDataTour && <LazyLoadImage effect="blur" id="imageDemoCoupon" src={demoCouponImg} alt="demo_coupon" />
                }
                {!showFakeDataTour && promos?.length > 0 &&
                  promos?.map((promo) => {
                    return (
                      <Card
                        key={promo.id}
                        promo={promo}
                        status={promo.status}
                      />
                    );
                  })
                }
                {!showFakeDataTour && promos.length === 0 && locationUserData.locationType!=='none' && userData.isLogged && (
                  <div className="pt-4">
                    <NoteAboutNoStores />
                  </div>
                )}
              </div>
            ) : (
              <>
                <StoresFilter />
                {!showFakeDataTour && isLoaded && storeView === "map" && (
                  <GoogleMap
                    id="DESCUENTON_MAP"
                    mapContainerClassName="w-full h-[70vh] -mt-4 relative"
                    center={center}
                    zoom={14.3}
                    options={{
                      minZoom: 14.3,
                      mapTypeControl: false,
                      zoomControl: false,
                      fullscreenControl: false,
                      streetViewControl: false,
                    }}
                  >
                    <Circle
                      center={{
                        lat: locationUserData.location?.latitude!,
                        lng: locationUserData.location?.longitude!,
                      }}
                      radius={Number(process.env.REACT_APP_MAP_RADIO_METERS) || 1000}
                      options={{ fillColor: "#76A8FF", strokeWeight: 0 }}
                    />
                    {/* Child components, such as markers, info windows, etc. */}
                    <Marker
                      position={{
                        lat: locationUserData.location?.latitude!,
                        lng: locationUserData.location?.longitude!,
                      }}
                    ></Marker>
                    <div className="fixed z-20 p-[10px] rounded-full cursor-pointer bottom-40 right-2 bg-[#D4E5FF] drop-shadow-xl">
                      <Map color="#070561" size={24} />
                    </div>
                    <div
                      id="buttonListView"
                      className="fixed z-20 p-[10px] rounded-full cursor-pointer bottom-28 right-2 bg-[#F0F3F4] drop-shadow-xl"
                      onClick={() => setStoreView("list")}
                    >
                      <List color="#070561" size={24} />
                    </div>
                    {
                      stores.length > 0 && (
                        <>
                          <div className="w-full absolute top-4">
                            <div className="mx-4 bg-[#C0C0EA] rounded-lg flex gap-2 py-[6px] px-4 shadow-lg">
                              <div><FilterIcon /></div>
                              <div className="font-libre-franklin text-xs">
                                Mostrando {stores.length} resultados a 1 km de distancia, en <span className="font-semibold">{locationUserData.location?.addressSelected.fullAddress}</span>
                              </div>
                            </div>
                          </div>
                          {
                            stores.map((store) => (
                              <Marker
                                key={store.id}
                                position={{
                                  lat: Number(store.latitude),
                                  lng: Number(store.longitude),
                                }}
                                icon={{
                                  url: process.env.REACT_APP_MAP_ICON_URL!,
                                }}
                                onClick={() => navigateToStoreDetail(store)}
                              />
                            ))
                          }
                        </>
                      )
                    }
                    {
                      stores.length === 0 && !isLoading &&
                      <ModalNoStores />
                    }
                  </GoogleMap>
                )}
                {
                  showFakeDataTour && (
                    <div id="mapDemo" className="w-screen h-[63vh] -mt-4">
                      <LazyLoadImage effect="blur" className="w-screen h-full" src={demoMapImg} alt="Demo Map" />
                    </div>
                  )
                }
                {!showFakeDataTour && storeView === "list" && (
                  <div className="contenedor">
                    <ScreenStoreAvailable stores={stores} setStores={setStores} loading={isLoading} />
                    <div
                      id="buttonMapView"
                      className="fixed p-[10px] rounded-full cursor-pointer bottom-40 right-2 bg-[#F0F3F4] drop-shadow-xl"
                      onClick={() => setStoreView("map")}
                    >
                      <Map color="#070561" size={24} />
                    </div>
                    <div className="fixed p-[10px] rounded-full cursor-pointer bottom-28 right-2 bg-[#D4E5FF] drop-shadow-xl">
                      <List color="#070561" size={24} />
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      {userData.isLogged && !isLocationInputFocused && !(!neverShowTourAgain && selectedTab === 'stores') && (
        <Footer setSelectedTab={setSelectedTab} />
      )}
    </>
  );
}
