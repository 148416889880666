import React from 'react'

interface FooterStepProps {
    stepNumber: number,
    customButtonText?: string,
    onSkip: () => void,
    onContinue: () => void,
}

const array = [1, 2, 3, 4, 5];

const FooterStep = ({ stepNumber, customButtonText, onSkip, onContinue }: FooterStepProps) => {
    return (
        <div className="flex items-center justify-between w-full">
            <div id='buttonTourSkip' className='px-4 cursor-pointer font-semibold text-[#0C0D0F]' onClick={() => onSkip()}>
                Saltar
            </div>
            <div className="flex gap-[6px] items-center">
                {
                    array.map((e) => {
                        if (e === stepNumber) {
                            return <div key={`step_${e}`} className='w-2 h-2 rounded-full bg-[#4A4458]' />
                        } else {
                            return <div key={`step_${e}`} className='w-2 h-2 rounded-full bg-[#CDD4D9]' />

                        }
                    })
                }
            </div>
            <div id='buttonTourNext' className='bg-[#C0C0EA] rounded-3xl py-2 px-4 font-semibold cursor-pointer' onClick={() => onContinue()}>
                {customButtonText ? customButtonText : 'Continuar'}
            </div>
        </div>
    )
}

export default FooterStep
