import { cn } from '../../utils/cn';

type HistorySummaryCardProps = {
    label: string;
    icon: string;
    value: string;
}

const HistorySummaryCard = ({ label, icon, value }: HistorySummaryCardProps) => {
    return (
        <div className={cn('history-summary-card rounded-lg px-4 pt-2 flex flex-col gap-1 pb-[10px]', value.length > 6 ? 'w-[168px]' : 'w-[130px]')}>
            <div className='font-roboto text-white text-[10px] text-center'>
                {label}
            </div>
            <div className="flex items-center gap-2">
                <img src={icon} className='w-8 h-8' alt="Coin icon" />
                <div className='text-[#FCCC0A] font-libre-franklin font-bold text-xl text-center'>
                    {value}
                </div>
            </div>
        </div>
    )
}

export default HistorySummaryCard
