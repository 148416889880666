import { useEffect, useState } from "react";
import { Pagination } from "../types/common"
import { cn } from "../utils/cn";

type PaginationProps = {
    pagination: Pagination;
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    onPageChange: (pageNumber:number) => void | Promise<void>;
}

const PaginationFooter = ({ pagination, currentPage, setCurrentPage,onPageChange }: PaginationProps) => {

    const [pageButtons, setPageButtons] = useState<number[]>([]);
    
        useEffect(() => {
            const array: number[] = [];
            if (pagination.total_pages > 5) {
    
                if (currentPage > 3) {
                    if (currentPage + 2 <= pagination.total_pages) {
                        array.push(currentPage - 2);
                        array.push(currentPage - 1);
                        array.push(currentPage);
                        array.push(currentPage + 1);
                        array.push(currentPage + 2);
                    } else {
                        array.push(pagination.total_pages - 4);
                        array.push(pagination.total_pages - 3);
                        array.push(pagination.total_pages - 2);
                        array.push(pagination.total_pages - 1);
                        array.push(pagination.total_pages);
                    }
                } else {
                    array.push(1)
                    array.push(2);
                    array.push(3);
                    array.push(4);
                    array.push(5);
                }
            } else {
                for (let i = 1; i <= pagination.total_pages; i++) {
                    array.push(i);
                }
            }
            setPageButtons(array)
        }, [currentPage, pagination.total_pages])

    const handlePageNavigationButtons = (type: 'previous' | 'next') => {
        if (type === 'previous') {
            if (currentPage !== 1) {
                onPageChange(currentPage - 1)
            }
        } else {
            if (currentPage !== pagination.total_pages) {
                onPageChange(currentPage + 1)
            }
        }
    }

    return (
        <>
            {
                pagination.total_pages > 1 && (
                    <div className={cn('flex mb-3 items-center w-full font-roboto text-xs font-semibold text-[#384753]', pagination.total_pages > 4 ? 'justify-between' : 'gap-4 justify-center')}>
                        <div className={cn('cursor-pointer', currentPage === 1 && 'opacity-30')} onClick={() => handlePageNavigationButtons('previous')}>
                            Anterior
                        </div>
                        {pageButtons.map((e) => (
                            <div className={cn('cursor-pointer py-2 px-3 rounded-full', currentPage === e && 'bg-[#070561] text-white')} onClick={() => onPageChange(e)}>
                                {e}
                            </div>
                        ))}
                        <div className={cn('cursor-pointer', currentPage === pagination.total_pages && 'opacity-30')} onClick={() => handlePageNavigationButtons('next')}>
                            Siguiente
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default PaginationFooter
