import { format, setHours, setMinutes, setSeconds } from 'date-fns';
import { couponsClient } from '../config/axios';
import { LocationData } from '../models/location';
import { axiosRequest } from '../utils/axiosRequest';

export const getPromos = async (location: LocationData | null) => {
    const params = location === null ? '' : `?lat=${location.latitude}&lng=${location.longitude}`;
    return await axiosRequest(couponsClient, 'GET', `/v1/coupon${params}`);
}

export const getPromoById = async (promoId: string) => {
    return await axiosRequest(couponsClient, 'GET', '/v1/coupon/' + promoId);
}

export const activatePromo = async (promotion_id: string) => {
    return await axiosRequest(couponsClient, 'POST', '/v1/coupon/activate', { promotion_id });
}

export const getMovements = async (userId: string, pageNumber: number, pageSize: number, fromDate?: Date, toDate?: Date) => {
    let url = `/v1/consumer/exchange-history/${userId}?page_number=${pageNumber}&page_size=${pageSize}`;
    if (fromDate) {
        const dateFromWithMinutes = setSeconds(setMinutes(setHours(fromDate, 0), 0), 0);
        const finalDateToBeUsed = toDate ? toDate : fromDate;
        const dateToWithMinutes = setSeconds(setMinutes(setHours(finalDateToBeUsed, 23), 59), 59);
        url += `&date_from=${dateFromWithMinutes.toISOString()}&date_to=${dateToWithMinutes.toISOString()}`;
    }
    return await axiosRequest(couponsClient, 'GET', url);
}
