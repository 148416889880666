import { CardinalOrientation, ElementInfo, Step, WalktourLogic } from "walktour";
import Step2 from "../components/walkthrough/Step2";
import Step3 from "../components/walkthrough/Step3";
import Step4 from "../components/walkthrough/Step4";
import Step5 from "../components/walkthrough/Step5";
import { TabsOptions } from "../components/screenHome/Tabs";


type SetSelectedTab = React.Dispatch<React.SetStateAction<TabsOptions>>;

const getCutoutPath = (target: ElementInfo,root:HTMLElement): string => {
    if (!target) {
        return '';
    }

    const {
        dims,
        coords
    } = target;

    const cutoutTop: number = coords.y;
    const cutoutLeft: number = coords.x;
    const cutoutRight: number = coords.x + dims.width;
    const cutoutBottom: number = coords.y + dims.height;

    return `M 0, 0
            L 0, ${root.scrollHeight}
            L ${cutoutLeft}, ${root.scrollHeight}
            L ${cutoutLeft}, ${cutoutTop}
            L ${cutoutRight}, ${cutoutTop}
            L ${cutoutRight}, ${cutoutBottom}
            L ${cutoutLeft}, ${cutoutBottom}
            L ${cutoutLeft}, ${root.scrollHeight}
            L ${root.scrollWidth}, ${root.scrollHeight}
            L ${root.scrollWidth}, 0`;
}

export const useWalktour = (setSelectedTab: SetSelectedTab) => {

    const goToStep5 = (next: WalktourLogic["next"]) => {
        setSelectedTab('stores');
        next();
    }

    const steps: Step[] = [
        {
            selector: '#disclaimerLocation',
            description: 'Step2',
            orientationPreferences: [CardinalOrientation.SOUTH],
            disableCloseOnClick: true,
            maskRadius: 24,
            customTooltipRenderer: (tourLogic) => {
                return <Step2 onSkip={() => tourLogic?.close()} onContinue={() => tourLogic?.next()} />
            }
        },
        {
            selector: '#tabPromotions',
            description: 'Step3',
            disableCloseOnClick: true,
            maskRadius: 4,
            orientationPreferences: [CardinalOrientation.SOUTHWEST],
            customTooltipRenderer: (tourLogic) => {
                return <Step3 onSkip={() => tourLogic?.close()} onContinue={() => tourLogic?.next()} />
            }
        },
        {
            selector: '#imageDemoCoupon',
            description: 'Step4',
            disableCloseOnClick: true,
            orientationPreferences: [CardinalOrientation.SOUTH],
            customTooltipRenderer: (tourLogic) => {
                return <Step4 onSkip={() => tourLogic?.close()} onContinue={() => goToStep5(tourLogic?.next!)} />
            }
        },
        {
            selector: '#mapDemo',
            description: 'Step5',
            orientationPreferences: [CardinalOrientation.NORTH],
            disableCloseOnClick: true,
            tooltipSeparation: -510,
            customTooltipRenderer: (tourLogic) => {
                return <Step5 onSkip={() => tourLogic?.close()} onContinue={() => tourLogic?.close()} />
            },
            renderMask: (maskOptions) => {
                const root = document.getElementById('root');
                return (
                    <svg className="pointer-events-none w-screen h-screen">
                        <defs>
                            <clipPath id='clip-path-0'>
                                <path d={getCutoutPath(maskOptions.targetInfo!,root!)}
                                />
                            </clipPath>
                        </defs>

                        <rect
                            x={0}
                            y={0}
                            width={root?.scrollWidth}
                            height={root?.scrollHeight}
                            fill='black'
                            fillOpacity={0.4}
                            pointerEvents='auto'
                            clipPath='url(#clip-path-0)'
                        />
                    </svg>
                );
            }
        },
    ]

    return { steps }
}
