import React from 'react'
import ArrowTooltip from './ArrowTooltip'
import { StepProps } from './setup'

const Step5 = ({ onSkip, onContinue }: StepProps) => {
    return (
        <div className='bg-[#F0F3F4] rounded-[14px] px-4 py-8 relative flex flex-col gap-4 text-[#070561] font-roboto'>
            <ArrowTooltip horizontalPositionClass='right-[50%]' />
            <div className="flex flex-col gap-1 items-center">
                <div className='font-extrabold text-sm'>Centros de canje</div>
                <div className="font-libre-franklin text-xs w-72">
                    Encuentra acá los lugares cercanos a tu dirección ingresada, donde podrás canjear tus promociones
                </div>
            </div>
            <div className="flex w-full justify-center items-center">
                <div id='buttonTourNext' className='bg-[#C0C0EA] rounded-3xl py-2 px-4 font-semibold cursor-pointer' onClick={() => onContinue()}>
                    Finalizar
                </div>
            </div>
        </div>
    )
}

export default Step5
