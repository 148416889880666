import { ReactComponent as Search } from '../../assets/icons/search.svg'
import copys from '../../utils/copys';
import IconLocation from '../../components/icons/IconLocationHome'
import { useLocationContext } from "../../hooks/useLocationContext"
import { useEffect, useState } from 'react';
import { Clipboard } from 'lucide-react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import AddressPrompt from './AddressPrompt';
import { AddressData } from '../../models/location';
import { TailSpin } from 'react-loader-spinner';
import { cn } from '../../utils/cn';

const LocationSection = () => {

    const [copiedValue, setCopiedValue] = useState('');
    const [loadingLocation, setLoadingLocation] = useState(false);
    const [showInitialPrompts, setShowInitialPrompts] = useState(true);
    const [defaultSuggestions, setDefaultSuggestions] = useState<AddressData[]>()
    const { isLocationInputFocused, setIsLocationInputFocused, locationUserData, setLocationUserData, showFakeDataTour, neverShowTourAgain, setHasStartedTour, setShowFakeDataTour } = useLocationContext();

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: "mx" },
            language: 'es-419'
        },
        debounce: 400,
    });

    const { getLocation } = useLocationContext();

    const handleFocus = () => {
        if (navigator.clipboard) (
            navigator.clipboard.readText().then(val => { if (val !== '') setCopiedValue(val) }).catch(e => console.log('error in reading clipboard', e))
        )
        setIsLocationInputFocused(true);
    }


    const handleButtonClick = async () => {
        setLoadingLocation(true);
        await getLocation();
        setLoadingLocation(false)
    }

    const handleSelect =
        (suggestion: google.maps.places.AutocompletePrediction) => {
            // When the user selects a place, we can replace the keyword without request data from API
            // by setting the second parameter to "false"
            setValue(suggestion.description, false);
            //clearSuggestions();

            const formattedPrompts = data.slice(0, 3).map(({ structured_formatting, description }) => {
                return {
                    mainText: structured_formatting.main_text,
                    secondaryText: structured_formatting.secondary_text,
                    fullAddress: description,
                }
            });

            setLoadingLocation(true);

            // Get latitude and longitude via utility functions
            getGeocode({ address: suggestion.description }).then((results) => {
                const { lat, lng } = getLatLng(results[0]);
                setLocationUserData({
                    locationType: 'manual',
                    location: {
                        latitude: lat,
                        longitude: lng,
                        addressPrompts: formattedPrompts,
                        addressSelected: {
                            mainText: suggestion.structured_formatting.main_text,
                            secondaryText: suggestion.structured_formatting.secondary_text,
                            fullAddress: suggestion.description,
                        }

                    }
                })
                setIsLocationInputFocused(false);
                if (!neverShowTourAgain) {
                    setShowFakeDataTour(true);
                }
                setHasStartedTour(true);
                setDefaultSuggestions(formattedPrompts)
            }).finally(() => setLoadingLocation(false));
        };

    const renderSuggestions = () => {
        return data.slice(0, 3).map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
                <AddressPrompt key={place_id} title={main_text} detail={secondary_text} onClick={() => handleSelect(suggestion)} />
            );
        });
    }

    useEffect(() => {
        setShowInitialPrompts(false)
    }, [data])

    useEffect(() => {
        if (isLocationInputFocused) {
            setHasStartedTour(false)
            if (locationUserData.location?.addressSelected) {
                setValue(locationUserData.location?.addressSelected.mainText, false)
                setDefaultSuggestions(locationUserData.location.addressPrompts);
                setShowInitialPrompts(true);
            }
        } else {
            setShowInitialPrompts(false);
        }
    }, [isLocationInputFocused]);

    useEffect(() => {
        if (locationUserData.locationType === 'gps') {
            setDefaultSuggestions(locationUserData.location?.addressPrompts);
            setShowInitialPrompts(false)
        }
    }, [locationUserData])


    return (
        <div className='flex flex-col gap-4 items-center w-full px-4'>
            {
                loadingLocation && (
                    <div className='w-screen h-screen z-50 bg-opacity-20 flex justify-center items-center'>
                        <TailSpin
                            visible={true}
                            height="80"
                            width="80"
                            color="#FFB100"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            strokeWidth={4}
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                )
            }

            <div className={cn('flex gap-4 w-full', isLocationInputFocused ? 'px-4' : 'justify-center')}>
                {
                    (!showFakeDataTour && locationUserData.locationType === 'none') || isLocationInputFocused
                        ?
                        (
                            <div className='bg-white py-3 px-4 flex gap-3 items-center rounded-2xl w-full'>
                                <Search />
                                <input id='inputAddress' disabled={!ready} value={value} onChange={(e) => setValue(e.target.value)} onFocus={handleFocus} className='w-full font-roboto text-sm font-normal text-[#070561] placeholder:text-[#5E6488]' type='text' placeholder={copys.inputLocationPlaceholder} />
                            </div>
                        )
                        :
                        (
                            <div className="flex w-full pl-0.5 gap-4 items-center">
                                <div id='textAddress' className="font-roboto text-sm text-[#070561] font-semibold w-full text-center" onClick={() => setIsLocationInputFocused(true)}>
                                    {showFakeDataTour ? 'Av. Paseo de la Reforma 305' : locationUserData.location?.addressSelected.mainText}
                                </div>
                                <svg className='mx-0.5' xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none" onClick={() => setIsLocationInputFocused(true)}>
                                    <path d="M6.49945 8.9357L2.5957 5.0282L3.65321 3.9707L6.49945 6.8132L9.3457 3.9707L10.4032 5.0282L6.49945 8.9357Z" fill="#070561" />
                                </svg>
                            </div>
                        )

                }
                {
                    !isLocationInputFocused && (
                        <div id='buttonGeolocation' className="bg-[#100E9D] rounded-xl p-4 cursor-pointer flex justify-center items-center" onClick={handleButtonClick}>
                            <IconLocation size={16} />
                        </div>
                    )
                }
            </div>
            {
                isLocationInputFocused && (
                    <>
                        {
                            copiedValue !== '' && (
                                <div id='promptCopiedValue' className='flex justify-start items-center w-full px-6 py-5 gap-1 font-libre-franklin text-[#070561] text-xs font-bold cursor-pointer' onClick={() => setValue(copiedValue)}>
                                    <Clipboard size={24} color='#100E9D' />
                                    {copys.promptPasteCopiedAddress}
                                </div>
                            )
                        }
                        {
                            showInitialPrompts && value !== '' && defaultSuggestions && defaultSuggestions?.length > 0 && defaultSuggestions?.map(suggestion => (
                                <AddressPrompt key={suggestion.secondaryText} title={suggestion.mainText} detail={suggestion.secondaryText} onClick={() => { }} />
                            ))
                        }
                        {
                            status === "OK" && !showInitialPrompts && renderSuggestions()
                        }
                        <div className='bg-[#617482] h-[0.5px] w-full' />
                        <div className='text-[#777] font-libre-franklin text-xs'>
                            Powered by <a className='font-semibold' href="https://www.google.com/maps" target="_blank" rel="noreferrer">Google</a>
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default LocationSection
