import { ReactComponent as Map } from '../../assets/icons/map.svg'
import { ReactComponent as UserHand } from '../../assets/icons/user-hand.svg';
import { ReactComponent as Star } from '../../assets/icons/star.svg';
import FooterStep from './Footer';
import { StepProps } from './setup';

const Step1 = ({ onSkip, onContinue }: StepProps) => {
    return (
        <div className='bg-white rounded-[14px] py-8 px-4 flex flex-col gap-4 items-center text-[#070561] font-roboto relative'>
            <div id='buttonTourStep1X' className="absolute top-4 right-5" onClick={() => onSkip()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="#FCCC0A" />
                </svg>
            </div>
            <div className="flex flex-col gap-1 items-center w-full">
                <div className='font-extrabold text-sm'>
                    ¡Nos renovamos para ti!
                </div>
                <div className='font-libre-franklin text-xs'>
                    Estos son algunos beneficios que trae la nueva versión:
                </div>
            </div>
            <div className='flex flex-col gap-2'>
                <div className="flex gap-2 items-center">
                    <Map />
                    <div className='text-sm'>Descuentos en tu zona</div>
                </div>
                <div className="flex gap-2 items-center">
                    <UserHand />
                    <div className='text-sm'>Promociones personalizadas</div>
                </div>
                <div className="flex gap-2 items-center">
                    <Star />
                    <div className='text-sm'>Canjes al instante</div>
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="281" height="2" viewBox="0 0 281 2" fill="none">
                <path d="M0.5 1H280.5" stroke="#CDD4D9" />
            </svg>
            <FooterStep stepNumber={1} customButtonText='Hacer tour' onSkip={onSkip} onContinue={onContinue} />
        </div>
    )
}

export default Step1
