import React, { PropsWithChildren } from 'react'

type ModalProps = { 
    isOpen:boolean, 
    onClose?:()=> void, 
    hasCloseButton?:boolean, 
    isCentered?:boolean
}

const Modal = ({ children, isOpen, onClose, hasCloseButton, isCentered }:PropsWithChildren<ModalProps>) => {
    const classJustify = isCentered ? 'justify-center':'justify-end';
    return (
        <>
            {isOpen && (
                <div className={`bg-black/50 w-screen h-screen px-5 fixed inset-0 z-40 flex flex-col ${classJustify} items-center`} onClick={onClose}>
                    <div className='flex flex-col items-center' onClick={e=>e.stopPropagation()}>
                        {hasCloseButton && (
                            <span className="close-btn" onClick={onClose}>
                                &times;
                            </span>
                        )}
                    {children}
                    </div>
                </div>
            )}
        </>
    );
  };

export default React.memo(Modal)
