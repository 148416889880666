import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ScreenHome from './screens/ScreenHome';
import ScreenLogin from './screens/ScreenLogin';
import ScreenSignUp from './screens/ScreenSignUp';
import Layout from './screens/layout/Layout';
import ScreenOTP from './screens/ScreenOTP';
import ScreenRegister from './screens/ScreenRegister';
import { Toaster } from 'react-hot-toast';
import ScreenPromoDetail from './screens/ScreenPromoDetail';
import ScreenProfile from './screens/ScreenProfile';
import ScreenTermsAndPrivacy from './screens/ScreenTermsAndPrivacy';
import { routePaths } from './utils/constants';
import { ScreenStoreDetails } from './screens/ScreenStoreDetails';
import ScreenHistory from './screens/ScreenHistory';

function App() {

  return (
    <>
      <Toaster />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<ScreenHome />} />
          <Route path={routePaths.HOME} element={<ScreenHome />} />
          <Route path={routePaths.DETAIL} element={<ScreenPromoDetail />} />
          <Route path={routePaths.PROFILE} element={<ScreenProfile />} />
          <Route path={routePaths.STORE_DETAILS} element={<ScreenStoreDetails />} />
          <Route path={routePaths.HISTORY} element={<ScreenHistory />} />
        </Route>
        <Route path={routePaths.SIGNUP} element={<ScreenSignUp />} />
        <Route path={routePaths.LOGIN} element={<ScreenLogin />} />
        <Route path={routePaths.OTP} element={<ScreenOTP />} />
        <Route path={routePaths.REGISTER} element={<ScreenRegister />} />
        <Route path={routePaths.TERMS} element={<ScreenTermsAndPrivacy />} />
       
        
        <Route path="*" element={<ScreenLogin />} />
      </Routes>
    </>
  );
}

export default App;
