import React, { useEffect } from 'react'
import ArrowTooltip from './ArrowTooltip'
import FooterStep from './Footer'
import { StepProps } from './setup'
import { useLocationContext } from '../../hooks/useLocationContext'


const Step2 = ({ onSkip, onContinue }: StepProps ) => {

    const { setIsLocationInputFocused, locationUserData,setShowFakeDataTour } = useLocationContext();


    useEffect(() => {
        if(locationUserData.locationType==='gps' || locationUserData.locationType==='manual'){
            onContinue();
        }
    }, [locationUserData]);

    const goToStep3 = () =>{
        setShowFakeDataTour(true);
        onContinue();
    }

    return (
        <div className='bg-[#F0F3F4] rounded-[14px] px-4 py-8 relative flex flex-col gap-4 text-[#070561] font-roboto'>
            <ArrowTooltip horizontalPositionClass='right-[45%]' />
            <div className="flex flex-col gap-1 items-center">
                <div className='font-extrabold text-sm'>Autoriza tu ubicación</div>
                <div className="font-libre-franklin text-xs w-72">
                    Permite tu ubicación o ingresa tu dirección para que te mostremos promociones cerca a ti
                </div>
                <div id='buttonTourStep2Address' className="bg-[#C0C0EA] rounded-3xl px-4 py-2 font-semibold cursor-pointer" onClick={() => setIsLocationInputFocused(true)}>
                    Ingresar dirección
                </div>
            </div>
            <FooterStep stepNumber={2} onSkip={onSkip} onContinue={()=>goToStep3()} />
        </div>
    )
}

export default Step2
