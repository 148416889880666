import React from 'react'
import ArrowTooltip from './ArrowTooltip'
import FooterStep from './Footer'
import { StepProps } from './setup'

const Step3 = ({ onSkip, onContinue }: StepProps) => {
    return (
        <div className='bg-[#F0F3F4] rounded-[14px] px-4 py-8 relative flex flex-col gap-4 text-[#070561] font-roboto'>
            <ArrowTooltip horizontalPositionClass='right-[75%]' />
            <div className="flex flex-col gap-1 items-center">
                <div className='font-extrabold text-sm'>Promociones disponibles</div>
                <div className="font-libre-franklin text-xs w-72">
                    Encuentra acá las promociones disponibles cercanas a la dirección que ingresaste
                </div>
            </div>
            <FooterStep stepNumber={3} onSkip={onSkip} onContinue={onContinue} />
        </div>
    )
}

export default Step3
