import React, { useEffect, useRef, useState } from 'react'
import Header from './layout/Header'
import { ReactComponent as CelebrationSVG } from '../assets/ilustraciones/celebration.svg'
import HistorySummaryCard from '../components/screenHistory/HistorySummaryCard'
import CoinStar from '../assets/img/coin.png'
import CoinLogo from '../assets/img/coin-logo.png'
import { ReactComponent as DashedLineSVG } from '../assets/ilustraciones/dashed-line.svg'
import { useAuthContext } from '../hooks/useAuthContext'
import { getMovements } from '../services/promosService'
import { format } from 'date-fns'
import { Movement } from '../models/history'
import MovementsCard from '../components/screenHistory/MovementsCard'
import CellphoneImg from '../assets/ilustraciones/cellphone.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { TailSpin } from 'react-loader-spinner'
import { cn } from '../utils/cn'
import { DateRangePicker } from '../components/ui/DatePicker'
import { DateRange } from 'react-day-picker'
import { ReactComponent as CalendarIcon } from '../assets/icons/calendar.svg'
import { Popover, PopoverAnchor, PopoverContent, PopoverTrigger } from '../components/ui/Popover'
import { PaginatedResponse, Pagination } from '../types/common'
import { useCallback } from 'react';
import PaginationFooter from '../components/Pagination';

interface ResponseGetMovements {
    history_data: Movement[],
    total_exchanges: number,
    total_savings: number,
}


const ScreenHistory = () => {

    const [selectedDate, setSelectedDate] = useState<DateRange | undefined>();
    const prevStartDate = useRef(selectedDate?.from);
    const prevEndDate = useRef(selectedDate?.to);
    const [movements, setMovements] = useState<Movement[]>([]);
    const [totalExchanges, setTotalExchanges] = useState<number>(0);
    const [totalSavings, setTotalSavings] = useState<number>(0);
    const [neverHasExchanged, setNeverHasExchanged] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const pageSize = 8;
    const [pagination, setPagination] = useState<Pagination>({
        page: 1,
        page_size: pageSize,
        total_pages: 0,
        total_rows: 0
    });
    const [currentPage, setCurrentPage] = useState(0);

    const { userData } = useAuthContext();

    useEffect(() => {
        if (selectedDate?.from !== selectedDate?.to) {
            setTimeout(() => {
                setIsCalendarOpen(false);
            }, 500)
        }
    }, [selectedDate?.to])

    useEffect(() => {
        if (!selectedDate) {
            setTimeout(() => {
                setIsCalendarOpen(false);
            }, 500)
        }
    }, [selectedDate])

    const fetchData = useCallback(async (page: number) => {
        const res: PaginatedResponse<ResponseGetMovements> = await getMovements(userData.user?.id!, page, pageSize, selectedDate?.from, selectedDate?.to);
        if (res.successful) {
            setPagination(res.data.pagination);
            setMovements(res.data.data.history_data);
            if (currentPage === 0 && res.data.pagination.total_rows === 0) { //currentPage is 0 only the first time component renders, so it has consulted without date filter
                setNeverHasExchanged(true);
            }
            setCurrentPage(res.data.pagination.page)
            setTotalExchanges(res.data.data.total_exchanges);
            setTotalSavings(res.data.data.total_savings)
        }
        setIsLoading(false);
    }, [userData.user?.id, selectedDate?.from, selectedDate?.to, currentPage]);

    useEffect(() => {
        if (!isCalendarOpen || currentPage === 0) {
            if (selectedDate?.from !== prevStartDate.current || selectedDate?.to !== prevEndDate.current || currentPage === 0) {
                setCurrentPage(1);
                fetchData(1);
                prevStartDate.current = selectedDate?.from;
                prevEndDate.current = selectedDate?.to;
            }
        }
    }, [isCalendarOpen, selectedDate, currentPage, fetchData]);

    const handlePageChange = async (pageNumber: number) => {
        setCurrentPage(pageNumber);
        await fetchData(pageNumber);
    }

    return (
        <div className='bg-[#F6F8FC] w-screen min-h-screen h-screen overflow-scroll flex flex-col gap-6 font-roboto'>
            <div className='bg-[#D4E5FF] rounded-b-[35px] w-full'>
                <Header customHeader='Tus ahorros' />
            </div>
            {
                isLoading && (
                    <div className=" w-full h-[80vh] flex flex-col items-center justify-center gap-2">
                        <TailSpin
                            visible={true}
                            height="80"
                            width="80"
                            color="#FFB100"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            strokeWidth={4}
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                        <div className=" text-sm font-normal text-text_strong">
                            Cargando...
                        </div>
                    </div>
                )
            }
            {
                !isLoading && (
                    <>
                        <Popover onOpenChange={setIsCalendarOpen} open={isCalendarOpen}>
                            <PopoverAnchor asChild >

                                <div className={cn('w-full flex gap-2 items-center', selectedDate ? 'pl-6 pr-3' : 'px-6')}>
                                    <PopoverTrigger asChild >
                                        <div className='bg-[#FFFFFF1A] border border-solid border-[#95A2AD] rounded-lg w-full flex p-4 gap-2 cursor-pointer' >
                                            <div className={cn('w-full', selectedDate && selectedDate.from ? 'text-[#384753]' : 'text-[#95A2AD]')}>
                                                {selectedDate && selectedDate.from ? format(selectedDate.from, 'dd/MM/yyyy') + (selectedDate.to ? ' - ' + format(selectedDate.to, 'dd/MM/yyyy') : '') : 'Elige una fecha'}
                                            </div>
                                            <CalendarIcon />
                                        </div>
                                    </PopoverTrigger>
                                    <PopoverContent className='p-2 rounded-xl bg-white shadow-lg border-none flex justify-center'>
                                        <DateRangePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                                    </PopoverContent>
                                    {
                                        selectedDate && (
                                            <div className="cursor-pointer font-semibold w-32 font-roboto text-[#070561]" onClick={() => setSelectedDate(undefined)}>
                                                Borrar filtro
                                            </div>
                                        )
                                    }


                                </div>
                            </PopoverAnchor>
                        </Popover>
                        <div className='w-full summary-card-shadow'>

                            <div className="bg-white rounded-t-xl mx-4 px-4 pt-4 pb-5 flex flex-col gap-4">
                                {
                                    (movements.length > 0 || neverHasExchanged) && (
                                        <div className='text-xl  font-semibold text-[#070561]'>
                                            Canjea, ahorra y gana
                                        </div>
                                    )
                                }
                                {
                                    neverHasExchanged ? (
                                        <div className="flex flex-col gap-2 items-center">
                                            <LazyLoadImage effect='blur' className='w-[100px]' src={CellphoneImg} alt="Icon not found" />
                                            <div className="font-libre-franklin text-[#070561] font-bold px-16 text-center">
                                                Todavía no has canjeado ninguna promoción
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {
                                                currentPage > 0 && movements.length === 0 ? (
                                                    <div className="flex flex-col gap-2 items-center">
                                                        <LazyLoadImage effect='blur' className='w-[100px]' src={CellphoneImg} alt="Icon not found" />
                                                        <div className="font-libre-franklin text-[#070561] font-bold px-16 text-center">
                                                            No hemos encontrado resultados para esas fechas
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <CelebrationSVG className='w-full' />
                                                        <div className="flex gap-3 px-2 w-full justify-center">
                                                            <HistorySummaryCard icon={CoinStar} label='Canjes realizados' value={totalExchanges.toLocaleString()} />
                                                            <HistorySummaryCard icon={CoinLogo} label='Has ahorrado' value={`$${totalSavings.toLocaleString()}`} />
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </div>
                            <div className="mx-4 w-auto relative">
                                <DashedLineSVG preserveAspectRatio='none' className='absolute top-[19px] mx-5 fill-available' />
                                <svg preserveAspectRatio='none' className='w-full' xmlns="http://www.w3.org/2000/svg" width="343" height="38" viewBox="0 0 343 38" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M0 0H343V12.866C339.491 13.1066 336.716 16.1417 336.716 19.8506C336.716 23.5594 339.491 26.5946 343 26.8352V38H0V26.8352C0.148693 26.8454 0.298706 26.8506 0.449881 26.8506C4.16913 26.8506 7.18417 23.7166 7.18417 19.8506C7.18417 15.9846 4.16913 12.8506 0.449881 12.8506C0.298706 12.8506 0.148693 12.8558 0 12.866V0Z" fill="white" />
                                </svg>
                            </div>
                            <div className="bg-white rounded-b-xl mx-4 px-4 pb-4 flex flex-col gap-2 text-center">
                                {
                                    neverHasExchanged ? (
                                        <div className=" font-semibold text-xs text-[#070561]">
                                            Una vez empieces a canjear, encontrarás acá el resumen de tus ahorros
                                        </div>
                                    ) : (
                                        <>
                                            {
                                                currentPage > 0 && movements.length === 0 ? (
                                                    <div className='font-semibold text-xs text-[#070561]'>
                                                        Selecciona otra fecha de consulta
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className=" font-semibold text-sm text-[#070561]">
                                                            ¡Felicidades {userData.user?.name.split(' ')[0]}!
                                                        </div>
                                                        <div className=" font-semibold text-xs text-[#070561]">
                                                            Has realizado {totalExchanges} canjes y ahorrado ${totalSavings} con Descuentón
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                }

                            </div>
                        </div>
                        {
                            movements.length > 0 && (
                                <>
                                    <div className="font-semibold  text-xl mx-4 text-[#070561]">
                                        Historial
                                    </div>
                                    <div className='mx-6 font-extrabold text-sm text-[#070561]'>
                                        Resumen
                                    </div>
                                    <div className="flex mx-6 px-4 py-2">
                                        <div className=' font-semibold text-sm text-[#384753] w-48'>Cupón</div>
                                        <div className=' font-semibold text-sm text-[#384753]'>Ahorro</div>
                                    </div>
                                    <div className="flex flex-col gap-2 mx-6 -mt-6 pb-2">
                                        {
                                            movements.map(m => <MovementsCard key={m.id} movement={m} />)
                                        }
                                        <PaginationFooter onPageChange={handlePageChange} setCurrentPage={setCurrentPage} currentPage={currentPage} pagination={pagination} />
                                    </div>
                                </>
                            )
                        }
                    </>
                )
            }

        </div>
    )
}

export default ScreenHistory
