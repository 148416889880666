
import { subMonths } from "date-fns";
import { DateRange, DayPicker } from "react-day-picker";

import { es } from "date-fns/locale";

type DateRangePickerProps = {
    selectedDate?: DateRange;
    setSelectedDate: React.Dispatch<React.SetStateAction<DateRange | undefined>>
}

export function DateRangePicker({ selectedDate, setSelectedDate }: DateRangePickerProps) {


    return (
        <DayPicker
            required={false}
            className="font-roboto scal"
            locale={es}
            classNames={{
                day: 'cursor-pointer w-9 h-9 justify-center rounded-md items-center flex font-medium text-[#0f172a]',
                selected: 'bg-[#100ea0] text-[#f8fafc]',
                range_start: 'bg-[#100ea0] text-[#f8fafc]',
                range_end: 'bg-[#100ea0] text-[#f8fafc]',
                range_middle: 'bg-[#f1f5f9] rounded-none [&>button]:text-[#0f172a]',
                week: 'mt-2 flex w-full',
                weekdays: 'flex',
                outside: 'text-[#64748b] opacity-50',
                disabled: 'text-[#64748b] opacity-50',
                weekday: 'text-[#64748b] w-9 text-[0.8rem]',
                month_grid: 'w-full border-collapse',
                today: 'border border-[#0f172a] rounded-md',
                month_caption: 'w-full text-center font-medium pt-3 mb-2',
                nav:'relative w-full',
                button_previous:'absolute left-0 top-2 opacity-50 rounded-full border border-solid border-[#100ea07d] p-[2px]',
                button_next:'absolute right-0 top-[335px] opacity-50 rounded-full border border-solid border-[#100ea07d] p-[2px]',
                chevron:'scale-[65%]'
            }}
            autoFocus
            mode="range"
            selected={selectedDate}
            onSelect={setSelectedDate}
            fixedWeeks
            numberOfMonths={2}
            showOutsideDays
            disabled={{ after: new Date() }}
            endMonth={new Date()}
            defaultMonth={subMonths(new Date(), 1)}
        />
    );
}