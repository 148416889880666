import React from 'react'

interface ArrowTooltipProps {
    horizontalPositionClass: string,
}

const ArrowTooltip = ({ horizontalPositionClass }: ArrowTooltipProps) => {
    return (
        <div className={`absolute -top-6 ${horizontalPositionClass}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 27 25" fill="none">
                <path d="M13.5 2L26 24H1L13.5 2Z" fill="#F0F3F4" stroke="#F0F3F4" />
            </svg>
        </div>
    )
}

export default ArrowTooltip
